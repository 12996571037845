export const state = {
  user: {}
};

export const mutations = {
    SET_LOGGED_USER(state, user){
        // console.log("SET_LOGGED_USER ", user);
        state.user = user;
    }
};

export const actions = {
    setLoggedUser({commit}, {user}){
        commit('SET_LOGGED_USER', user);
    }
};
