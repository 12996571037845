/*
id producto
 "idProducto": 108, 
  "codigo": "LLISA 3.05 RPS264NTER",  
  partida 
  cantidad, cantidadreal solo se capturan cuando unidad = 1
  "longitud": "3.05",    
  "mlpieza": "3.05",
  "medidaespecial": "",
  "idTipoProducto": 1,
  "tipoProducto": "LÁMINA",
  "aplicacion": "LISA",
  "rolloCodigo": "RPS264NTERG33",
  "rolloMaterial": "PINTRO BLANCO",
  "rolloProveedor": "TERNIUM",
  "rolloCalibre": 26,
  "rolloPies": "4",
  "unidad": "PIEZA",
  "precio1": "527.77",
  "precio2": "494.22",
  "precio3": "523.20",
  "preciomendez": "471.10"
  idUnidad
  1 Metro lineal
  4 Pieza
  3 KIlo

*/ 

export const state = {
     productos: [ 
    //  {
//   "idProducto": 694,
//   "codigo": "LLISA 6.10 RPS264NTERG33",
//   "longitud": "6.10",
//   "mlpieza": "6.10",
//   "medidaespecial": "",
//   "idTipoProducto": 1,
//   "tipoProducto": "LÁMINA",
//   "aplicacion": "LISA",
//   "rolloCodigo": "RPS264NTERG33",
//   "rolloMaterial": "PINTRO BLANCO",
//   "rolloProveedor": "TERNIUM",
//   "rolloCalibre": 26,
//   "rolloPies": "4",
//   "idUnidad": 4,
//   "precio1": "1055.54"
// },
//  {
//   "idProducto": 122,
//   "codigo": "LKR-18 RZA242NTER",
//   "longitud": "",
//   "mlpieza": "0.00",
//   "medidaespecial": "",
//   "idTipoProducto": 1,
//   "tipoProducto": "LÁMINA",
//   "aplicacion": "KR-18",
//   "rolloCodigo": "RZA242NTERG33",
//   "rolloMaterial": "ZINTRO ALUM",
//   "rolloProveedor": "TERNIUM",
//   "rolloCalibre": 24,
//   "rolloPies": "2",
//   "idUnidad": 1,
//   "precio1": "88.73"
// },
//   {
//   "idProducto": 350,
//   "codigo": "RZA242NTER",
//   "longitud": "",
//   "mlpieza": "0.00",
//   "medidaespecial": "",
//   "idTipoProducto": 5,
//   "tipoProducto": "ROLLO",
//   "aplicacion": "--NO APLICA--",
//   "rolloCodigo": "RZA242NTERG33",
//   "rolloMaterial": "ZINTRO ALUM",
//   "rolloProveedor": "TERNIUM",
//   "rolloCalibre": 24,
//   "rolloPies": "2",
//   "idUnidad": 3,
//   "precio1": "31.52"
// }
]
};

export const mutations = {
    // TOOGLE_METALICA_ACANALADO(state, id){
    //     console.log(`Tootle metalica acanalado ${id}`);
    //     const item = state.metalica.acanalados.find((i) => i.id == id);
    //     if (item) {
    //         item.checked = !item.checked;
    //         Object.assign(item, { ...item});

    //         localStorage.setItem(metalidaAcanaladosKey, JSON.stringify(state.metalica.acanalados)) // OR
    //     }
    // }
    ADD_PRODUCT_TO_CART(state, product){
        state.productos.push(product);
    }
};

export const actions = {
    // toogleMetalicaAcanalado({commit}, {id}){
    //     commit('TOOGLE_METALICA_ACANALADO', id);
    // }
    addProductToCart({commit}, { product }){
        commit("ADD_PRODUCT_TO_CART", product);
    }
};

export const getters = {
    getProductsOnCart(state){
         return state.productos;
    }
};
