
var metalicaacanalados = `[
            { "id": 6, "nombre": "KR-18", "checked": true},
            { "id": 2, "nombre": "LISA", "checked": false},
            { "id": 24, "nombre": "LOSACERO", "checked": false},
            { "id": 3, "nombre": "O-100", "checked": true},
            { "id": 4, "nombre": "R-101", "checked": false},
            { "id": 5, "nombre": "RN-100", "checked": true},
            { "id": 45, "nombre": "TEJ GAL", "checked": false},
            { "id": 999, "nombre": "ROLLOS", "checked": false}
        ]`;
var metalidaAcanaladosKey = "metalicaacanalados";

export const state = {
    metalica: {
        acanalados: JSON.parse(localStorage.getItem(metalidaAcanaladosKey) || metalicaacanalados)
        // [
        //     { id: 6, nombre: "KR-18", checked: false},
        //     { id: 2, nombre: "LISA", checked: false},
        //     { id: 24, nombre: "LOSACERO", checked: false},
        //     { id: 3, nombre: "O-100", checked: true},
        //     { id: 4, nombre: "R-101", checked: false},
        //     { id: 5, nombre: "RN-100", checked: true},
        //     { id: 45, nombre: "TEJ GAL", checked: false},
        //     { id: 999, nombre: "ROLLOS", checked: false}
        // ]
    
    }
};

export const mutations = {
    TOOGLE_METALICA_ACANALADO(state, id){
        console.log(`Tootle metalica acanalado ${id}`);
        const item = state.metalica.acanalados.find((i) => i.id == id);
        if (item) {
            item.checked = !item.checked;
            Object.assign(item, { ...item});

            localStorage.setItem(metalidaAcanaladosKey, JSON.stringify(state.metalica.acanalados)) // OR
        }
    }
};

export const actions = {
    toogleMetalicaAcanalado({commit}, {id}){
        commit('TOOGLE_METALICA_ACANALADO', id);
    }
};

export const getters = {
    getMetalicaAcanalados(state){
        return state.metalica.acanalados;
    }
};
